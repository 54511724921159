<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      width="250"
      class="lowShadow"
    >
      <v-list dense>
        <v-list-item
          v-for="(item, i) in rotasPermitidas"
          :key="i"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color="white"
      clipped-left
      dense
      app
      class="justify-center lowShadow"
    >
      <v-app-bar-nav-icon @click.native="toggleMenu()"></v-app-bar-nav-icon>
      <div>
        <v-chip title="Modulo ativo">{{ getUser.role }}</v-chip>
      </div>
      <v-spacer></v-spacer>
      <div class="text-center">
        <img src="@/assets/logo-deitada.png" width="130" />
      </div>
      <v-spacer></v-spacer>
      <v-icon class="mx-2">mdi-bell</v-icon>
      <v-btn v-if="getUser.role == 'ADMIN'" icon to="configuracoes">
        <v-icon class="mx-2">mdi-cog</v-icon>
      </v-btn>
      <v-menu v-model="toggleMenuAvatar" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on">
            <avatar :avatar-url="getUserImageURL" />
          </v-btn>
        </template>
        <v-card>
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ getUser.nome }} </v-list-item-title>
                <v-list-item-subtitle
                  class="grey--text text-capitalizecase text--lighten-1"
                  >{{ getUser.role }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list class="pa-0">
            <v-list-item @click="$router.push('/dashboard/perfil')">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item link @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="mainContent">
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import avatar from "@/components/avatar";
export default {
  components: {
    avatar
  },
  data() {
    return {
      drawer: true,
      toggleMenuAvatar: false,
      items: [
        {
          title: "Início",
          icon: "mdi-home",
          link: "/dashboard/home",
          roles: ["ADMIN", "FINANCEIRO", "AGENCIA"]
        },
        {
          title: "Prêmios",
          icon: "mdi-gift-open",
          link: "/dashboard/premios",
          roles: ["ADMIN"]
        },
        {
          title: "Bilhetes FCZ",
          icon: "mdi-ticket-confirmation",
          link: "/dashboard/bilhetes-fcz",
          roles: ["ADMIN", "FINANCEIRO", "SUPORTE", "AGENCIA"]
        },
        {
          title: "Estatística FCZ",
          icon: "mdi-chart-bar",
          link: "/dashboard/estatistica-fcz",
          roles: ["ADMIN", "FINANCEIRO"]
        },
        {
          title: "Agências",
          icon: "mdi-domain",
          link: "/dashboard/agencia",
          roles: ["ADMIN"]
        },
        {
          title: "Atendimento",
          icon: "mdi-message-bulleted",
          link: "/dashboard/atendimento",
          roles: ["ADMIN", "FINANCEIRO"]
        },
        {
          title: "Usuários",
          icon: "mdi-account-tie",
          link: "/dashboard/usuarios",
          roles: ["ADMIN", "AGENCIA"]
        },
        {
          title: "Participantes",
          icon: "mdi-account-group",
          link: "/dashboard/participantes",
          roles: ["ADMIN", "AGENCIA"]
        },
        {
          title: "Turmas",
          icon: "mdi-calendar-month",
          link: "/dashboard/turmas/lista",
          roles: ["ADMIN", "AGENCIA"]
        },
        {
          title: "Itens do Plano",
          icon: "mdi-view-agenda",
          link: "/dashboard/itens-plano",
          roles: ["ADMIN"]
        },
        {
          title: "Relatórios",
          icon: "mdi-finance",
          link: "/dashboard/relatorios",
          roles: ["ADMIN"]
        },
        {
          title: "Arquivo Retorno",
          icon: "mdi-bank-transfer",
          link: "/dashboard/arquivo-retorno",
          roles: ["ADMIN"]
        },
        {
          title: "Boletos",
          icon: "mdi-text-box",
          link: "/dashboard/boletos",
          roles: ["ADMIN"]
        },
        {
          title: "Pagamentos",
          icon: "mdi-currency-usd",
          link: "/dashboard/pagamentos",
          roles: ["ADMIN", "FINANCEIRO", "SUPORTE"]
        },
        {
          title: "Pacotes",
          icon: "mdi-card-bulleted",
          link: "/dashboard/pacotes",
          roles: ["ADMIN", "FINANCEIRO", "SUPORTE"]
        },
        {
          title: "Instituições",
          icon: "mdi-home-city",
          link: "/dashboard/instituicoes",
          roles: ["ADMIN", "FINANCEIRO", "SUPORTE"]
        },
        {
          title: "Cursos",
          icon: "mdi-script-text",
          link: "/dashboard/cursos",
          roles: ["ADMIN", "FINANCEIRO", "SUPORTE"]
        },
        {
          title: "Meu Perfil",
          icon: "mdi-badge-account",
          link: "/dashboard/perfil",
          roles: ["ADMIN", "AGENCIA"]
        },
        {
          title: "Contas a Receber",
          icon: "mdi-credit-card-multiple",
          link: "/dashboard/contas-a-receber",
          roles: ["AGENCIA"]
        }
      ],
      mini: false
    };
  },
  computed: {
    getUser() {
      return this.$store.getters["gestao/getUser"];
    },
    getUserFoto() {
      return this.$store.getters["gestao/getUserFoto"];
    },
    rotasPermitidas() {
      return this.regraDeMenu(this.items);
    },
    getUserImageURL() {
      return this.getUserFoto ? this.getUserFoto : null;
    }
  },
  mounted() {
    this.$store.dispatch("gestao/START_SYSTEM");
  },
  methods: {
    toggleMenu() {
      this.mini = !this.mini;
      this.drawer = !this.drawer;
    },
    logout() {
      localStorage.setItem("APT-ADMTOKEN", "");
      this.$router.push("/login");
    },
    regraDeMenu(lista) {
      let ruleUser = this.$store.getters["gestao/getUserRole"];
      return lista.filter(a => a.roles.includes(ruleUser));
    }
  }
};
</script>

<style lang="css">
.v-navigation-drawer__border {
  display: none;
}
</style>
<style lang="scss" scoped>
.mainContent {
  min-height: 100vh;
  background-color: #f2f6f8;
}
</style>
